import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { Grid, Button, Typography } from "@mui/material";
import Modal from "../Modal/Modal";
import BotManagerForm from "./BotManagerForm";
import {
  BotUser,
  deleteBotManager,
  getBotManager,
  responseOfBotManager,
} from "../../app/botManager/botManager";
import Toaster from "../Toaster/Toaster";
import Loader from "../Loader/Loader";
import { AlertColor } from "@mui/material";
import DeleteModal from "../Modal/DeleteModal";
import { Box } from "@mui/system";
import { doc, deleteDoc} from "firebase/firestore";
import {db} from '../../firebase'

export default function BotManager() {
  const [openForm, setOpenForm] = useState(false);
  const [editFormDetails, setEditFormDetails] = useState<BotUser | null>(null);
  const [data, setData] = useState<BotUser[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: responseOfBotManager<Array<BotUser>> = await getBotManager();
      setData(response?.data || []);
    } catch (err: any) {
      setSeverity("error");
      setMessage(err?.message || "error occurred.");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  
  const deleteBotManagerFromFirebase = async (id: string, suffix: string = "") => {
    try {
      const docRef = doc(db, 'network_users', `${id}${suffix}`);
      await deleteDoc(docRef);
    } catch (err: any) {
      console.error("Error deleting document: ", err);
    }
  };

  return (
    <Layout title="Bot Manager">
      <Grid container>
        <Grid xs={12} sx={{ mb: "10px" }}>
          <Button
            variant="contained"
            onClick={() => {
              setEditFormDetails(null);
              setOpenForm(true);
            }}
          >
            Create New Bot
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ height: "80vh", overflowY: "auto" }}>
        <Grid
          container
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {data?.map((item: BotUser) => (
            <Grid
              xs={3.8}
              key={item?.id}
              sx={{
                border: "1px solid",
                borderColor: "#c9c7c7",
                padding: "10px",
                borderRadius: "7px",
                mr: "10px",
                mb: "10px",
                boxShadow: 1,
                height: "220px",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                {" "}
                {item?.username}
              </Typography>
              <Typography sx={{ mb: "5px" }}>{`${item?.description?.slice(
                0,
                100
              )}...`}</Typography>
              <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                {" "}
                Has FAISS File :
              </Typography>
              <Typography variant="body2" sx={{ mb: "10px" }}>
                {item?.faissFilePath}
              </Typography>
              <Button
                variant="contained"
                sx={{ mr: "10px" }}
                onClick={() => {
                  setEditFormDetails(item);
                  setOpenForm(true);
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={async () => {
                  setEditFormDetails(item);
                  setOpenDeletePopup(true);
                }}
              >
                Delete
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal
        open={openForm}
        title={editFormDetails ? "Edit Bot" : "Create Bot"}
        onClose={() => {
          setOpenForm(false);
        }}
        form={
          <BotManagerForm
            setOpenForm={setOpenForm}
            editFormDetails={editFormDetails}
            setSeverity={setSeverity}
            setMessage={setMessage}
            setOpenAlert={setOpenAlert}
            fetchData={fetchData}
            setLoading={setLoading}
          />
        }
      />

      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />

      <DeleteModal
        description={"you want to delete this bot manager."}
        open={openDeletePopup}
        handleClose={() => {
          setOpenDeletePopup(false);
        }}
        handleDelete={async () => {
          if (editFormDetails?.id) {
            setOpenDeletePopup(false);
            setLoading(true);
            const response: responseOfBotManager<null> = await deleteBotManager(
              editFormDetails?.id
            );

            if (response?.code == 200) {
              await deleteBotManagerFromFirebase(editFormDetails?.id);
              await deleteBotManagerFromFirebase(editFormDetails?.id, "#AnonChat");
              setOpenAlert(true);
              setSeverity("success");
              setLoading(false);
              fetchData();
              setMessage(response?.message || "Record Deleted Successfully.");
            } else {
              setOpenAlert(true);
              setSeverity("error");
              setLoading(false);
              setMessage(response?.message || "Unable to delete the record.");
            }
          }
        }}
      />

      <Loader loading={loading} />
    </Layout>
  );
}
