import React , {useEffect , useState}from 'react'
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import { GridColDef , GridRowParams } from '@mui/x-data-grid'
import { getFeedAction , addFeedAction , editFeedAction , deleteFeedAction } from '../../app/feeds/feeds'
import { deletePostAction , getPostAction} from '../../app/post/post'
import { Delete as DeleteIcon} from "@mui/icons-material";
import Button from '@mui/material/Button';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import postV2Schema from '../../schema/postV2'
import {Type} from '../../enum/index'
import {PostPayload } from '../../../types/common'
import { postSchema } from '../../schema/post'
import { PostList } from '../../app/post/post'
import { getUsers } from '../../app/users/users'
import { PromptTypes } from '../../enum/index'
import { getFilteredUsers , schedulePost } from '../../app/post/post'
import dayjs from 'dayjs';
import axios from 'axios'
import Comments from './Comments/Comments'
import ViewComment from './viewComments/Comments'
import { candidateLinkedInPromptDetails } from '../../app/post/post'
import { getCommunityAction } from '../../app/community/community'
import { getSeederCandidatesByCommunity } from '../../app/seeders/seeders'
import DeleteModal from '../Modal/DeleteModal'
import { getPostActionV2, schedulePostv2 } from '../../app/postSchedulerv2/postSchedulerv2'
interface EducationProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editPromptModal:boolean
    editDetails:GridRowParams | null
    promptId:string
    deletePromptModal:boolean,
    viewComments?:boolean
}
const PostSchedulerV2GeneralisedComponent: React.FC<EducationProps> =  ({ deletePromptModal,promptId, addModal , closeModal , columns , editPromptModal , editDetails , viewComments }) => {
  const [prompt, setPrompt] =  useState<Array<PostList>>([]);
  const [users, setUsers] =  useState<Array<candidateLinkedInPromptDetails>>([]);
  const [toggle , setToggle] = useState<boolean>(false);
  const [loading , setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('');
  const [communities , setCommunities] = React.useState<any>([])
  const [seeders , setSeeders] = React.useState<any>([])
  const [count , setCount] = React.useState<any>([])
  const [disable, setDisable] = React.useState(true);
  const [picture, setPicture] = React.useState(false);
  const [rowCount,setRowCount]= useState<number>(0);
  const [isComments, setIsComments] = React.useState(false);
  const [seedersRowCount,setSeedersRowCount]= useState<number>(0);
  const [CommentData,setCommentData]= useState<any>();
  const [postDateAndTime,setPostDateAndTime]= useState<any>(null);
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [paginationForSeeders , setpaginationForSeeders] = React.useState({
    pageNo:1,
    pageSize:10,
    id:null
  })
  const [paginationForCommunity , setpaginationForCommunity] = React.useState({
    pageNo:1,
    pageSize:10,
  })
  const [selectedCommunityId,setSelectedCommunityId]= useState
 (null);


  useEffect(() => {
    setDataLoading(true);
    // Fetch user data from an API when the component mounts
    getPostActionV2(pagination)
      .then((res) => {
        setDataLoading(false);
        if(!res.status){
        }else{
        setRowCount(res?.data?.totalRecord || 0)
        setPrompt(res.data.list ? res.data.list : []);
        }     
      })
      .catch((err) => {
        setSeverity('error')
          setOpenAlert(true)
          setMessage(err ? err.message : 'Error')
          setDataLoading(false);
      });

  }, [toggle , pagination]);
useEffect(() =>{
  getComunities(paginationForCommunity)
},[paginationForCommunity])
  useEffect(() => {
   if(selectedCommunityId != null){
    setDisable(selectedCommunityId ? false : true)
    getSeeder(paginationForSeeders);
   }
  }, [selectedCommunityId , paginationForSeeders])

const getUser = (name:string) =>{
  setLoading(true)
  getFilteredUsers(name)
  .then((res) =>{
    setLoading(false)
    setUsers(res.candidateLinkedInPromptDetails ? res.candidateLinkedInPromptDetails.reverse() : [])
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err?.message : 'Error')
  })
}
const getComunities = (payload:any) =>{
  setDataLoading(true)
  getCommunityAction(payload)
  .then((res) =>{
    if(!res.status){
    }else{
    setCount(res?.data?.totalCount)
    setCommunities(res.data?.list ? res.data?.list : []);
    setDisable(selectedCommunityId ? false : true)
    }    
    
  })
  .catch((err) =>{
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err?.message : 'Error')
  }).finally(() =>{
    setDataLoading(false)
  })
}
const getSeeder = (payload) =>{
  setDataLoading(true)
  getSeederCandidatesByCommunity(payload)
  .then((res) =>{ 
    setPicture(false)
    setDataLoading(false)
    if(!res.status){
    }else{
      res.data?.seederCandidateListResponseDtos.length > 0 ? setPicture(true) : setPicture(false)
    setSeeders(res.data?.seederCandidateListResponseDtos ? res.data?.seederCandidateListResponseDtos : []);
    setSeedersRowCount(res?.data?.totalCount)
    }    
    
  })
  .catch((err) =>{
    setPicture(false)
    setDataLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err?.message : 'Error')
  })
}
const initialvalues = {
  communityId:'',
  candidateLinkedInId:'',
  title:"",
  body:"",
  dateTime:'',
  postUrl:undefined,
  isAlias:false
  
} 

const closeDropdown = () =>{
  setUsers([])
}
async function getLocation(lat:string , long:string){
  const filteredObject:any = {};
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyC3eqAsW-sYeBvN28o3ZWarZFzeqUPLIjE`;
 await  axios.get(apiUrl)
  .then((res:any) =>{
   res.data.results[0].address_components.forEach((component:any) => {
      component.types.forEach((type:any) => {
        if (
          type === "sublocality" ||
          type === "locality" ||
          type === "administrative_area_level_2" ||
          type === "administrative_area_level_1"
        ) {
          filteredObject[type] = component?.long_name;
        }
      });
    });
  
  })
  .catch((err:any) =>{
    // setLoading(false)
    // setSeverity('error')
    // setOpenAlert(true)
    // setMessage(err ? err?.error_message: 'Error')
  })
  return filteredObject;
}

const addPostv2 = async(values:any) =>{
  //  const filteredData =  await getLocation(values.locLat , values.locLong)
  const date = values.dateTime.$d;
  let url = values['postUrl'] ? values['postUrl'] .split(',') : ""
  values['postUrl'] = url[1]
  const { myFile, ...restValues } = values;
  const payload = {
    ...restValues,
    promptType:'PROMPT',
    comments:[],
    // promptType:values.promptType === 'Beyond Business' ? 'BUSINESS_BEYOND' : values.promptType === 'Advice Needed' ? 'ASSIST' : values.promptType === 'Reguler - No Tag' ? 'PROMPT' : values.promptType === 'Event' ? 'EVENT' : '',
    timeZone :values?.dateTime ? Intl.DateTimeFormat().resolvedOptions().timeZone : null,
    dateTime:values?.dateTime ? dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
    // subLocality:filteredData?.sublocality ||filteredData?.locality || filteredData?.administrative_area_level_2 || filteredData?.administrative_area_level_1 || 'Miami',
    // locality:filteredData?.locality  || filteredData?.administrative_area_level_2 || filteredData?.administrative_area_level_1 ||"Miami",
    // subAdministrativeArea:filteredData?.administrative_area_level_2 ||filteredData?.administrative_area_level_1 || 'Miami',
    // administrativeArea: filteredData?.administrative_area_level_1 || 'Miami',
}
  setLoading(true)
  //Call Api To Add User
   schedulePostv2(payload)
    .then((res) =>{
    if(!res?.status){
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Request failed due to duplicity')
    }else{
      setLoading(false)
      setSeverity('success')
      setOpenAlert(true)
      setMessage(res?.message ? res.message : 'Successfully Posted')
      setToggle(!toggle)
    }
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : 'Error')
    })
}

const deletePost = async (id:string) => {
  setLoading(true)
  deletePostAction(id)
  .then((res) =>{
    if(!res?.status){
      setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Record not found')
    }else{
      setLoading(false)
    setSeverity('success')
    setOpenAlert(true)
    setMessage(res?.message ? res.message :'Successfully Deleted')
    setToggle(!toggle)
    }
  })
  .catch((err) =>{
    setLoading(false)
    setSeverity('error')
    setOpenAlert(true)
    setMessage(err ? err.message : 'Error')
   
  })
  //Call Api To Delete User
};

const getCommunityId =(data:any)=>{
  setSelectedCommunityId(data?.id);
  setpaginationForSeeders((prev) =>{
    return{
        ...prev,
        id:data?.id
    }
  })
}
function setPageForSeeders(){
  setpaginationForSeeders((prev) =>{ return {...prev , pageSize: prev?.pageSize + 10}})
}
function setPage(){
  setpaginationForCommunity((prev) =>{ return {...prev , pageSize: prev?.pageSize + 10}})
}
const items = [
  {
    candidate_LINKED_IN_ID:PromptTypes.BusinessBeyond
  },
  {
    candidate_LINKED_IN_ID:PromptTypes.Assist
  },
  {
    candidate_LINKED_IN_ID:PromptTypes.Event
  },
  {
    candidate_LINKED_IN_ID:PromptTypes.Prompt
  }
]
function reset(){
  setpaginationForCommunity(() =>{
    return {
      pageNo:1,
      pageSize:10
    }
  })
  setpaginationForSeeders(() =>{
    return {
      pageNo:1,
      pageSize:10,
      id:null
    }
  })
  setDisable(true)
}
const addModalFields = [
  {
    name: 'communityId',
    label: 'Community',
    type:'drop',
    items:communities  ,
    key:'communityName',
    close:closeDropdown, 
    loading:dataLoading,
    totalCount:count,
    callBack: setPage,
    rowCount:count,
    paginationSize:paginationForCommunity?.pageSize,
    // more:true,
    handleChange:getCommunityId,
    picture:false
  },
    {
      name: 'candidateLinkedInId',
      label: 'Select sedder',
      type:'drop',
      items:seeders,
      key:'fullName',
      loading:dataLoading,
      specialKey:'communityId',
      callBack:setPageForSeeders,
      more:false,
      payload:{
        id:true,
        pagination:true
      },
      disable:disable,
      picture:picture,
      rowCount:seedersRowCount,
      paginationSize:paginationForSeeders?.pageSize,
      
    },
    {
      name: 'title',
      label: 'Title',
      type:'text',
      required:true
      
    },
    {
      name: 'body',
      label: 'Content',
      type:'textarea', 
    },
    {
      name: 'dateTime',
      label: 'Date Picker',
      type:"DatePicker"
    },
  
    {
      name: 'myFile',
      label: 'upload pic',
      type:'file'
      
    },
    {
      label: 'Use Alias',
      name: 'isAlias',
      type:"checkbox",
     
    },
];
const editModalFields = [
  {
    name: 'name',
    label: 'Name',
    type:'text'
    
  },
];

  const handleSubmit = (values : PostPayload , type : string)=> {
    // Handle form submission here
    if(type === Type.Add){ 
      closeModal()
      addPostv2({...values,body:values?.body ? values.body :null })
    }
    
};
function pagenated(pageNo:number , pageSize:number){
  setpagination(() =>{
    return {
      pageNo:pageNo,
      pageSize:pageSize
    }
  })
}
function handleComments(data){
if(data?.dateTime !== "" && data?.title !== "" && data?.communityId !== "" && data?.fullName !== ""){
  setIsComments(true)
  setCommentData(data ? data : null)
  setPostDateAndTime(formatDateTimeToLocal(data?.dateTime?.$d))
}
 

}
const formatDateTimeToLocal = (date) => {
    if (!date) return '';
  
    // Convert the selected date to the local timezone
    const dt =  date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Automatically detects the user's local timezone
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Set to false for 24-hour format
    });
    // setPostDate(date)
    return dt;
  };
  function handlePostEdit (){
    setIsComments(false)
  }
  function closeCommentModal(){
    closeModal()
    setIsComments(false)
    setToggle(!toggle)
  }
  return <>
   <DataGridTable columns={columns} row={prompt} loading={dataLoading} pagenated={pagenated} rowCount={rowCount} />
    {/* { Add Modal } */}
    <Modal
        open={addModal}
        title="Schedule Post"
        onClose={() => { closeModal() , setPicture(false),reset()}} 
        form={<GenericForm validationSchema={postV2Schema} handleComments={handleComments} title='Schedule Post' imageUploadField='postUrl' type='Add Postv2' fields={addModalFields}  initialValues={initialvalues} onSubmit={handleSubmit} />}
      />
      {/* Edit Modal */}
      <Modal
              open={editPromptModal}
              title="Edit Post"
              onClose={() => {return closeModal(), reset()}}
              form={<GenericForm title='Save' imageUploadField='postUrl' validationSchema={postSchema}  editedDetails={editDetails} type='Edit Post'  fields={editModalFields} initialValues={initialvalues}   onSubmit={handleSubmit}></GenericForm>}
      />
    
      {/* delete modal */}

      <DeleteModal description={"you want to delete this scheduled post."} open={deletePromptModal} handleClose={closeModal} handleDelete={()=>{
        deletePost(promptId);
        closeModal();
      }} />
      {/* Comment modal */}
      <Modal
     open={isComments || false}
     title="Comments"
     onClose={() => { setIsComments(false)}}
     maxWidth='lg'
     form={<Comments closeCommentModal={closeCommentModal}  handlePostEdit={handlePostEdit} postDateAndTime={postDateAndTime} commentData={CommentData}/>}
  />
    <Modal
     open={viewComments || false}
     title="Comments"
     onClose={closeModal}
     maxWidth='lg'
     form={<ViewComment closeViewComment={() => {closeModal() , setToggle(!toggle)}}  commentData={editDetails}/>}
  />
        <Loader loading={loading}/>
      <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
  </>
   

  
}
export default PostSchedulerV2GeneralisedComponent;