import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  addBotManager,
  BotManagerFormValues,
  BotUser,
  editBotManager,
  responseOfBotManager,
} from "../../app/botManager/botManager";
import { doc, setDoc, updateDoc} from "firebase/firestore";
import {db} from '../../firebase'


const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  prompt: Yup.string().required("Prompt is required"),
  faissFile: Yup.mixed()
    .required("FAISS File is required")
    .test("fileType", "Only .faiss files are allowed", (file) => {
      return file instanceof File && file.name.endsWith(".faiss");
    }),
  profilePicture: Yup.mixed().required("Profile Picture is required"),
});

const validationSchemaEdit = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  prompt: Yup.string().required("Prompt is required"),
   faissFile: Yup.mixed()
    .notRequired()
    .test("fileType", "Only .faiss files are allowed", (file) => {
      return file instanceof File ? file.name.endsWith(".faiss") : true;
    }),
  profilePicture: Yup.mixed().notRequired(),
});

const UploadButton = styled("label")({
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: "#1976d2",
  color: "white",
  padding: "8px 16px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#1565c0",
  },
});

const BotManagerForm = ({
  setOpenForm,
  editFormDetails,
  setSeverity,
  setMessage,
  setOpenAlert,
  fetchData,
  setLoading,
}) => {
  const [profilePicPreview, setProfilePicPreview] = useState<string | null>(
    editFormDetails?.profilePhotoUrl || null
  );
  const [previousUploadFaissFile, setpreviousUploadFaissFile] = useState<
    string | null
  >(editFormDetails?.faissFilePath || null);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const downloadFaissFile = () => {
    if (previousUploadFaissFile) {
      const link = document.createElement("a");
      link.href = previousUploadFaissFile;
      link.download = "FAISS file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const addBotManagerToFirebase =async(values)=>{
    const now = new Date();
  const milliseconds = now.getTime();
  try{
    const docRef = doc(db, 'network_users', values?.id);
    await setDoc(docRef, {
      id:`${values?.id}`,
      name: `${values?.username}` ,
      about:`${values?.description}`,
      aliasPhoto:"bot",
      email:`${values?.email}`,
      anonName:"bot",
      username: `${values?.username}`,
      push_token:`Take FCM Token From Login API Response`,
      image:`${values?.profilePhotoUrl}`,
      created_at:milliseconds ? String(milliseconds) : "",
      is_online:true,
      last_active:milliseconds ? String(milliseconds) : "",
      isSeeder:false,
      isBot:true
    });
  }catch(err:any){
    throw new Error(err)
  }
  }

  const addBotManagerToFirebaseAnon =async(values)=>{
    const now = new Date();
  const milliseconds = now.getTime();
  try{
    const docRef = doc(db, 'network_users', `${values?.id}#AnonChat`);
    await setDoc(docRef, {
      id:`${values?.id}#AnonChat`,
      name: `${values?.username}` ,
      about:`${values?.description}`,
      aliasPhoto:"bot",
      email:`${values?.email}`,
      anonName:"bot",
      username: `${values?.username}`,
      push_token:`Take FCM Token From Login API Response`,
      image:`${values?.profilePhotoUrl}`,
      created_at:milliseconds ? String(milliseconds) : "",
      is_online:true,
      last_active:milliseconds ? String(milliseconds) : "",
      isSeeder:false,
      isBot:true
    });
  }catch(err:any){
    throw new Error(err)
  }
  }

  const updateDocumentField = async (documentId, fieldName, newValue) => {
    const docRef = doc(db, "network_users", documentId);
    try {
        await updateDoc(docRef,{
            [fieldName]: newValue
        });
    } catch (error) {
        console.error("Error updating document: ", error);
    }
  };

  return (
    <Formik<BotManagerFormValues>
      initialValues={{
        name: editFormDetails?.username || "",
        description: editFormDetails?.description || "",
        prompt: editFormDetails?.prompt || "",
        faissFile: null,
        profilePicture: null,
      }}
      validationSchema={
        editFormDetails ? validationSchemaEdit : validationSchema
      }
      onSubmit={async (values: BotManagerFormValues) => {
        setOpenForm(false);
        setLoading(true);
        try {
          if (values?.profilePicture) {
            const base64ProfilePic = (await convertToBase64(
              values.profilePicture
            )) as string;
            values.profilePicture = base64ProfilePic.split(",")[1];
          }
          if (values?.faissFile) {
            const base64FaissFile = (await convertToBase64(
              values.faissFile
            )) as string;
            values.faissFile = base64FaissFile.split(",")[1];
          }
          if (editFormDetails) {
            const response: responseOfBotManager<BotUser> = await editBotManager(
              values,
              editFormDetails.id
            );
            if (response?.code === 200) {
              updateDocumentField(response?.data?.id, 'image' , response?.data?.profilePhotoUrl || "")
              updateDocumentField(`${response?.data?.id}#AnonChat` , 'image' , response?.data?.profilePhotoUrl ||"")
              updateDocumentField(response?.data?.id, 'name', response?.data?.username)
              updateDocumentField(`${response?.data?.id}#AnonChat`, 'name' , response?.data?.username)
              updateDocumentField(response?.data?.id, 'username', response?.data?.username)
              updateDocumentField(`${response?.data?.id}#AnonChat`, 'username' , response?.data?.username)
              updateDocumentField(response?.data?.id, 'about', response?.data?.description)
              updateDocumentField(`${response?.data?.id}#AnonChat`, 'about' , response?.data?.description)
              setSeverity("success");
              fetchData();
              setMessage("Record Edit Successfully.");
              setOpenAlert(true);
            }
          } else {
            const response: responseOfBotManager<BotUser> = await addBotManager(values);
            if (response?.code === 200) {
              await addBotManagerToFirebase(response?.data);
              await addBotManagerToFirebaseAnon(response?.data)
              setSeverity("success");
              fetchData();
              setMessage(response?.message || "Record Added Successfully.");
              setOpenAlert(true);
            }
          }
        } catch (err) {
          setSeverity("error");
          setMessage("Unable to create subreddit.");
          setOpenAlert(true);
        } finally {
          setLoading(false);
        }
      }}
    >
      {({ setFieldValue, errors, touched, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <Field
                name="name"
                as={TextField}
                label="Name"
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal">
              <Field
                name="description"
                as={TextField}
                label="Description"
                fullWidth
                multiline
                rows={2}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal">
              <Field
                name="prompt"
                as={TextField}
                label="Prompt"
                fullWidth
                multiline
                rows={2}
                error={touched.prompt && Boolean(errors.prompt)}
                helperText={touched.prompt && errors.prompt}
              />
            </FormControl>

            {previousUploadFaissFile && (
              <Box sx={{ display: "flex", justifyContent: "left" }}>
                <Button onClick={downloadFaissFile}>uploaded faiss file</Button>
              </Box>
            )}

            <FormControl fullWidth margin="normal">
              <Typography variant="body1" component="label" htmlFor="faissFile">
                FAISS File
              </Typography>
              <input
                id="faissFile"
                name="faissFile"
                type="file"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    "faissFile",
                    event.target.files ? event.target.files[0] : null
                  );
                }}
                style={{ display: "block", marginTop: "12px" }}
              />
              {touched.faissFile && errors.faissFile && (
                <Typography color="error">{errors.faissFile}</Typography>
              )}
            </FormControl>

            <FormControl fullWidth variant="filled" margin="normal">
              <UploadButton>
                <input
                  name="profilePicture"
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file = event.target.files
                      ? event.target.files[0]
                      : null;
                    if (file) {
                      setFieldValue("profilePicture", file);
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setProfilePicPreview(reader.result as string);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                <CameraAltIcon style={{ marginRight: "5px" }} /> Upload Profile
                Picture
              </UploadButton>
              {touched.profilePicture && errors.profilePicture && (
                <Typography color="error">{errors.profilePicture}</Typography>
              )}
            </FormControl>

            {profilePicPreview && (
              <Box sx={{ display: "flex", justifyContent: "left" }}>
                <img
                  src={profilePicPreview}
                  alt="Profile Pic"
                  style={{ width: "100px", height: "100px",}}
                />
              </Box>
            )}

            <Box display="flex" justifyContent="center">
              <FormControl margin="normal">
                <Button variant="contained" color="primary" type="submit">
                  {editFormDetails ? "Edit Bot" : "Create Bot"}
                </Button>
              </FormControl>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default BotManagerForm;
