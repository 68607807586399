import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { getSeederAction, resetSeeders, selectSeederState } from '../../app/seeders/seeders';
import Modal from '../Modal/Modal'
import GenericForm from '../form/GenericForm'
import DataGridTable from '../table/DataGridTable'
import {  GridColDef,GridRowParams } from '@mui/x-data-grid';
import { addIndustry, editIndustry, getIndustry, deleteIndustry } from '../../app/industry/industry';
import { Delete as DeleteIcon} from "@mui/icons-material";
import { getCommunityAction } from '../../app/community/community';
import { AlertColor } from '@mui/material';
import Loader from '../Loader/Loader'
import Toaster from '../Toaster/Toaster'
import {Type} from '../../enum/index'
import { Payload } from '../../../types/common'
import { IndustryList } from '../../app/industry/industry';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setCommunitie } from '../../app/seeders/seeders';
import { editSeederAction } from '../../app/seeders/seeders';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


interface IndustryProps{
    addModal:boolean
    closeModal:() => void
    columns : GridColDef[]
    editIndustryModal:boolean
    editDetails:GridRowParams | null
    deleteIndustryModal:boolean
    industryId:string,
    swipeEdit:boolean
  }
const  SwipeMode: React.FC<IndustryProps> = ({swipeEdit,industryId,deleteIndustryModal,addModal ,closeModal , columns , editIndustryModal}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading , setLoading] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity , setSeverity] = React.useState('') 
  const [toggle , setToggle] = React.useState<boolean>(false);
  const [seeders , setSeeders] = React.useState<any>([])
  const [totalCount , setTotalCount] = React.useState<any>(0)
  const [editDetails , setEditDetails] = React.useState<any>(0)
  const [communities , setCommunities] = React.useState<any>([])
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:1
  })
  const [editMode , setEditMode] = React.useState<any>(false)
  const dispatch = useAppDispatch()
  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     setActiveStep(0)
//     if(activeStep === 0){
//   setpagination((prev) => {
//         return{
//             pageSize:5,
//             pageNo:prev?.pageNo + 1
//         }
//     })
//     setActiveStep(0);
//     }

setpagination((prev) => {
            return{
                pageSize:1,
                pageNo:prev?.pageNo + 1
            }
        })
  };

  const handleBack = () => {
    // if(activeStep > 0){
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
        
       
    // }else if(activeStep === 0){
    //     setpagination((prev) => {
    //         return{
    //             pageSize:5,
    //             pageNo:prev?.pageNo - 1
    //         }
    //     })
    // }
   
    setpagination((prev) => {
                return{
                    pageSize:1,
                    pageNo:prev?.pageNo - 1
                }
            })
  };
  function setSwipeEditDetails(){
    dispatch(setCommunitie(editDetails))
  }
 swipeEdit &&  setSwipeEditDetails()
  const getComunities = (name:string) =>{
    setLoading(true)
    getCommunityAction(pagination)
    .then((res) =>{
      setLoading(false)
      setCommunities(res.data?.list ? res.data?.list : []);
    })
    .catch((err) =>{
      setLoading(false)
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err?.message : 'Error')
    })
  }
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  React.useEffect(() =>{
    setLoading(true);
    // Fetch user data from an API when the component mounts
    getSeederAction(pagination)
    .then((res) =>{
      setLoading(false)
      if(!res.status){
      }else{
        setTotalCount(res?.data?.totalCount)
        setSeeders(res.data?.seederCandidateListResponseDtos ? res.data?.seederCandidateListResponseDtos ?.reverse() : [])
        dispatch(setCommunitie(res?.data?.seederCandidateListResponseDtos[0]?.communities))
        setEditDetails(res?.data?.seederCandidateListResponseDtos[0]?.communities)
      }    
    })
    .catch((err) =>{
      setSeverity('error')
      setOpenAlert(true)
      setMessage(err ? err.message : "Error")
      setLoading(false);
    })
},[pagination , toggle])
const editSeeder = async(values : any) =>{

  const val:string[] = []
  values?.communities?.map((value:any)=>val.push(value?.id))
  const payloadData = {
  aboutMe: values?.aboutMe,
  anonymousName:values?.anonymousName,
  username: values?.username,
  communities: values?.communities?.map((com)=>com?.id ? com?.id:com),
  email: values?.email,
  firstName: values?.firstName,
  id: values?.id,
  isSeeder: true,
  lastName: values?.lastName,
  photoUrl: values?.photoUrl
}

  setLoading(true)
//Call Api To Edit User
let url = payloadData['photoUrl'] ? payloadData['photoUrl'] .split(',') : ""
payloadData['photoUrl'] = url[1]
editSeederAction(payloadData)
.then((res) =>{
    if(!res?.status){
      setSeverity('error')
        setOpenAlert(true)
        setMessage(res?.message ? res.message : 'Record not found') 
        setLoading(false)
    }else{
      dispatch(resetSeeders())
      setSeverity('success')
        setOpenAlert(true)
        setMessage(res?.message ? res.message : 'Successfully Updated')
        setToggle(!toggle)   
        setLoading(false)
    }
})
.catch((err) =>{
  setSeverity('error')
  setOpenAlert(true)
  setMessage(err ? err.message : "Error")
  setLoading(false)
})
}
const editModalFields = [
    {
      name: 'firstName',
      label: 'FirstName',
      type:'text',
      required:true
      
    },
    {
      name: 'lastName',
      label: 'LastName',
      type:'text',
      required:true
      
    },
    {
      name: 'communities',
      label: 'Communities',
      type:'dropDown',
      items:communities,
      key:'communityName',
      callBack:getComunities,
      // close:closeDropdown, 
    },
    {
        name: 'username',
        label: 'Username',
        type:'text',
        required:true
        
    },
    {
      name: 'email',
      label: 'Email',
      type:'email',
      required:true
    },
    {
      name: 'aboutMe',
      label: 'AboutMe',
      type:'textarea',
      required:true
      
    },
    {
      name: 'myFile',
      label: 'upload pic',
      type:'file'
      
  }
    
  ];
  const initialvalues = {
    firstName:"",
    lastName:"",
    anonymousName:"",
    username: "",
    aboutMe:"",
    communities:null,
    email:"",
    photoUrl:"",
    myFile:undefined
    } 
function capitalizeWords(str:string) {
    return str?.replace(/\b\w/g, char => char?.toUpperCase());
}
const handleSubmit = (values : Payload , type : string)=> {
    // Handle form submission here
   

    if(type === Type.Edit){
      closeModal()
      editSeeder(values)
    }
    
  };
  return (
 <>
    <Box sx={{display:'flex',justifyContent:'center', alignItems:'center'}}>
        <Box sx={{maxWidth:'100%', flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection:'column',
          alignItems: 'center',
          height: 'auto',
          pl: 2,
          bgcolor: 'background.default',
          padding:'20px'
        }}
      >
        <Typography>{capitalizeWords(seeders[activeStep]?.fullName)}</Typography>
        <Typography><span style={{fontWeight:'bold'}}>Username: </span>{seeders[activeStep]?.username}</Typography>
        <Typography><span style={{fontWeight:'bold'}}>About Me: </span>{seeders[activeStep]?.aboutMe}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={false}
      >
        {seeders?.map((step:any, index:any) => (
          <div style={{display:'flex',justifyContent:'center'}} key={step.name}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: '45vh',
                  display: 'block',
                  overflow: 'hidden',
                  
                }}
                src={step?.photoUrl}
                alt={step?.fullName}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={seeders?.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={pagination?.pageNo === totalCount}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} 
          disabled={pagination.pageNo === 1 && activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    <Loader loading={loading}/>
    {/* Edit Modal */}
    <Modal
              open={editIndustryModal}
              title="Edit Seeder"
              onClose={closeModal}
              form={<GenericForm title='Save'  imageUploadField='photoUrl' editedDetails={seeders[0]} type='Edit Seeder' fields={editModalFields} initialValues={initialvalues}    onSubmit={handleSubmit}></GenericForm>}
      />
    </Box>
          <Toaster handleClose={() => setOpenAlert(false)} severity={severity as AlertColor} open={openAlert} message={message}/>
 </>
  );
}

export default SwipeMode;
