import React, { useEffect, useState, useRef } from "react";
import { Layout } from "../../layout/Layout";
import { Typography } from "@mui/material";
import SubredditCard from "./SubredditCard";
import SubredditForm from "./SubredditForm";
import { getSubredditManager } from "../../app/content/subredditManager";
import { Box } from "@mui/system";
import Toaster from "../Toaster/Toaster";
import { AlertColor } from "@mui/material";
import Loader from "../Loader/Loader";
import { UpvoteBoosterResponse } from "../form/type";

const SubredditManager = () => {
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null); // Reference to the scrollable container
  const [pagination , setpagination] = React.useState({
    pageNo:1,
    pageSize:10
  })
  const [rowCount,setRowCount]= useState<number>(0);
  interface SubredditResponse {
    size: number;
    list: any;
    totalCount: number;
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: UpvoteBoosterResponse<SubredditResponse> =
        await getSubredditManager(pagination);
      setData(response?.data?.list || []);
      setRowCount(response?.data?.totalCount || 0)
    } catch (err: any) {
      setSeverity("error");
      setMessage(err?.message || "error occurred.");
      setOpenAlert(true);
    } finally {
      setLoading(false);
    }
  };
  function pagenated(){
    setpagination((prev) =>{
      return {
        pageNo:1,
        pageSize:prev?.pageSize + 10
      }
    })
  }
  useEffect(() => {
    fetchData();

    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight) {
          if (data?.length < rowCount) {
            pagenated(); // Trigger pagination if there are more rows to load
          }
        
        }
      }
    };

    const container:any =containerRef?.current;
    if (container) {
      container?.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [pagination]);

  return (
    <Layout title="Subreddit Manager">
      <Box
        ref={containerRef}
        sx={{ height: "80vh", overflowY: "auto" }} // Ensure the container is scrollable
      >
        <Typography sx={{ fontWeight: "500", mb: "10px", fontSize: "22px" }}>
          Manage Subreddits
        </Typography>

        {data?.map((item: any) => (
          <Box key={item.id} sx={{ mb: "10px" }}>
            <SubredditCard
              data={item}
              setOpenAlert={setOpenAlert}
              setMessage={setMessage}
              setSeverity={setSeverity}
              setLoading={setLoading}
              fetchData={fetchData}
            />
            <SubredditForm
              data={item}
              setOpenAlert={setOpenAlert}
              setMessage={setMessage}
              setSeverity={setSeverity}
              fetchData={fetchData}
            />
          </Box>
        ))}
      </Box>

      <Toaster
        handleClose={() => setOpenAlert(false)}
        severity={severity as AlertColor}
        open={openAlert}
        message={message}
      />
      <Loader loading={loading} />
    </Layout>
  );
};

export default SubredditManager;
