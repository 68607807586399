import React, { useEffect } from 'react';
import { Field, useFormik} from 'formik';
import Loader from '../Loader/Loader';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Input, FormHelperText, Typography, NativeSelect} from '@mui/material';
import { GenericFormProps} from './type';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimeValidationError } from '@mui/x-date-pickers';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Type } from '../../enum/index'
import Dialog from '../Dialog/Dialog'
import Tooltip from '@mui/material/Tooltip';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadingIcon from '@mui/icons-material/Downloading';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
// import excel from '../../images/excel.png'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { selectCommunityState, setImage } from '../../app/communityEditDetails/communityEdit';
import { useAppSelector } from '../../app/hooks';
import { useAppDispatch } from '../../app/hooks';
import { selectSeederState, setCommunitie } from '../../app/seeders/seeders';
import Box from '@mui/material/Box';
import { FormControlLabel} from '@mui/material';
import PostDialog from '../NotificationScheduler/PostDialog'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative', // Add position relative to the container
  transition: 'background-color 3s ease',
  '&:hover .clear-icon-container': {
    display: 'block', // Show the clear icon when the container is hovered
  },
});
const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
const ImageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: 'row',

});

const ClearIconContainer = styled('div')({
  display: 'none', // Initially hide the clear icon
  position: 'absolute', // Position the clear icon absolutely
  top: '5px',
  right: 0,
  cursor: 'pointer',
  color:"grey",
  verticalAlign:"center",
});




interface OptionType {
  [key: string]: string; // Assuming option[field.key] returns a string
}

function GenericForm<T>({
  title,
  changeSchema,
  closeModal,
  label="",
  editedDetails,
  type,
  fields,
  onSubmit,
  initialValues,
  validationSchema,
  imageUploadField,
  align,
  handleComments,
  isImageRequired
}:GenericFormProps<T>) {
  const [selectedFileName , setSelectedFileName] =React.useState(editedDetails && editedDetails?.name)
  const [selectedAliasFileName , setSelectedAliasFileName] =React.useState(editedDetails && editedDetails?.name)
  const [file, setFile] = React.useState(editedDetails?.photoUrl ? editedDetails?.photoUrl : '');
  const [aliasFile, setAliasFile] = React.useState(editedDetails?.aliasUrl ? editedDetails?.aliasUrl : '');
  const [CSVFile , setCSVFile]= React.useState("")
  const [error, setError] = React.useState<DateTimeValidationError | null>(null);
  const [disable , setDisable] = React.useState<boolean>(false)
  const [isValidFormat , setIsValidFormat] = React.useState<boolean>(editedDetails && editedDetails.photoUrl ? true : false)
  const [isAliasValidFormat , setIsAliasValidFormat] = React.useState<boolean>(editedDetails && editedDetails.aliasUrl ? true : false)
  const [CsvSubmitted , setCsvSubmitted] = React.useState<boolean>(false)
  const [CsvWrongFormat , setCsvWrongFormat] = React.useState<boolean>(false)
  const [imageSubmitted , setImageSubmitted] = React.useState<boolean>(false)
  const [aliasImageSubmitted , setAliasImageSubmitted] = React.useState<boolean>(false)
  const [imageWrongFormat , setImageWrongFormat] = React.useState<boolean>(false)
  const [aliasImageWrongFormat , setAliasImageWrongFormat] = React.useState<boolean>(false)
  const [selectedValues , setSelectedValues] = React.useState<any>(editedDetails ? editedDetails.communityType : "")
  const [dialogOpen , setDialogOpen] = React.useState(false)
  const [isDisable , setIsDisable] = React.useState(false)
  const [communtiyType , setCommunityType] = React.useState(editedDetails ? editedDetails?.communityType : '')
  const [communityError , setCommunityError] = React.useState(false)
  const [seeder , setSeeder] = React.useState<any>(null)
  const [postOpen , setPostOpen] = React.useState<boolean>(false)
  const [post , setPost] = React.useState<any>()
  const [postData , setPostData] = React.useState<any>()
  const [dropLoading, setDropLoading] = React.useState(false);
  const [pagination , setPagination] = React.useState<any>({
    pageNo:1,
    pageSize:10
  }
)
  const communityState:any = useAppSelector(selectCommunityState).editDetails
  const dispatch = useAppDispatch();
  const communityData:any = useAppSelector(selectSeederState)?.communitites
  const url = useAppSelector(selectCommunityState).url
  const [selectedOptions, setSelectedOptions] = React.useState(communityState ?  communityState?.communityTopicResponses : []);

  const {
    values,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    resetForm,
    setValues
    
  } = useFormik({
    initialValues: editedDetails ? editedDetails : initialValues,
    validationSchema,
    onSubmit: () => {
      if(editedDetails?.communityType && editedDetails.communityType == "ANON"){
        const communityId: string[] = [];  
        selectedOptions && selectedOptions?.map((val:any)=>val?.id && communityId?.push(val?.id))
        values.communityTopic = communityId
      }
      onSubmit(values, type ? type.split(" ")[0] : "")
    },
  });


  function setCommunityFieldCallBack(value:any){
    setFieldValue('communities', value);
  }
  function handleCommunities(){
    // dispatch(setCommunitie([]))
    setDialogOpen(true)
  }

  function handleMore(field:any){
    
    setPagination((prev:any) => {
      const updatedPageSize = prev?.pageSize + 5;
      // Assuming you want to reset the page number to 1 when updating the page size
      const updatedPageNo = 1;
  
      // Call field.callBack after updating pagination
      field?.callBack && field?.callBack({ pageSize: updatedPageSize, pageNo: updatedPageNo });
    
      // Return the updated pagination object
      return {
        pageSize: updatedPageSize,
        pageNo: updatedPageNo
      };
    });
}
  const handleCommunityClose = () => {
    setDialogOpen(false)
    setPostOpen(false)
  };
  const handleFileChange = (e: React.BaseSyntheticEvent) => {
    if(type && type.split(" ")[0] === Type.Add){
 
      const myFile = e.target.files[0];

   
      if (myFile && myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg"){

        setFieldValue('myFile', myFile ? myFile : file)
        setSelectedFileName(myFile ? myFile?.name : 'image')
        setFile(myFile)
        getBase64(myFile)
        setIsValidFormat(true)
        setImageWrongFormat(false)
        setImageSubmitted(false)
      } else if(myFile){
        setFieldValue('myFile', myFile ? myFile : file)
        setFile(myFile ? myFile : file)
        setIsValidFormat(myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg" ? true : false && file ? true : false )
        setImageWrongFormat(true)
        setImageSubmitted(false)
      }
    }else{
      
      const myFile = e.target.files[0];
   
      if (myFile && myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg"){
        setFieldValue('myFile', myFile ? myFile : file)
        setSelectedFileName(myFile?.name)
        setImageWrongFormat(false)
        setImageSubmitted(false)
        setFile(myFile)
        getBase64(myFile)
        setIsValidFormat(true)
        changeSchema!== undefined ? changeSchema(true) : null
      }else if(myFile){
        setSelectedFileName(myFile?.name)
        setImageSubmitted(false)
        setImageWrongFormat(true)
        changeSchema!== undefined ? changeSchema(true) : null
        setFieldValue('myFile', myFile ? myFile : file)
        setFile(myFile ? myFile : file)
        setIsValidFormat(myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg" ? true : false && file ? true : false )
        setTimeout(() => {
          handleBlur(e);
        }, 100);
      }
    }
  
   
  };
  const handleAliasChange = (e: React.BaseSyntheticEvent) => {
    if(type && type.split(" ")[0] === Type.Add){
 
      const myFile = e.target.files[0];

   
      if (myFile && myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg"){

        setFieldValue('aliasFile', myFile ? myFile : file)
        setSelectedAliasFileName(myFile ? myFile?.name : 'image')
        setAliasFile(myFile)
        getAliasBase64(myFile)
        setIsAliasValidFormat(true)
        setAliasImageWrongFormat(false)
        setAliasImageSubmitted(false)
      } else if(myFile){
        setFieldValue('aliasFile', myFile ? myFile : file)
        setAliasFile(myFile ? myFile : file)
        setIsAliasValidFormat(myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg" ? true : false && file ? true : false )
        setAliasImageWrongFormat(true)
        setAliasImageSubmitted(false)
      }
    }else{
      
      const myFile = e.target.files[0];
   
      if (myFile && myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg"){
        setFieldValue('aliasFile', myFile ? myFile : file)
        setSelectedAliasFileName(myFile?.name)
        setAliasImageWrongFormat(false)
        setAliasImageSubmitted(false)
        setAliasFile(myFile)
        getAliasBase64(myFile)
        setIsAliasValidFormat(true)
        changeSchema!== undefined ? changeSchema(true) : null
      }else if(myFile){
        setSelectedAliasFileName(myFile?.name)
        setAliasImageSubmitted(false)
        setAliasImageWrongFormat(true)
        changeSchema!== undefined ? changeSchema(true) : null
        setFieldValue('aliasFile', myFile ? myFile : file)
        setAliasFile(myFile ? myFile : file)
        setIsAliasValidFormat(myFile?.type === "image/png" || myFile?.type === "image/jpg" || myFile?.type === "image/jpeg" ? true : false && file ? true : false )
        setTimeout(() => {
          handleBlur(e);
        }, 100);
      }
    }
  
   
  };
  function handlePost (){
    setPostOpen(true)
  }
  function handlePostClose(){
    setPostOpen(false)
  }
  function getBase64(file:File ) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFieldValue(imageUploadField ? imageUploadField : "", reader ? reader.result : "");
      setDisable(false)
    };
    reader.onerror = function (error) {
    };
  }
  function getAliasBase64(file:File ) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFieldValue( "aliasUrl", reader ? reader.result : "");
      setDisable(false)
    };
    reader.onerror = function (error) {
    };
  }
   function removeFile(){
    if(type && type.split(" ")[0] === Type.Upload){
      setCSVFile("")
      setDisable(false)
      setSelectedFileName("")
      setFieldValue('myFile',"")
    }else{
      dispatch(setImage(url ? url : ""))
      setCSVFile("")
      setDisable(false)
      setFile(editedDetails ? editedDetails.photoUrl : "")
      // setSelectedFileName(editedDetails ? "Uploaded Image" : "")
      setSelectedFileName('')
      setFieldValue('myFile',"")
      setFieldValue(imageUploadField ? imageUploadField : "","")
      setIsValidFormat(false)
      type && type.split(" ")[0] === Type.Edit &&  changeSchema!== undefined ? changeSchema(true) : null
    }
  
   
  }
  function removeAliasFile(){
    setAliasFile(editedDetails ? editedDetails.photoUrl : "")
    setSelectedAliasFileName('')
    setFieldValue('aliasUrl',"")
    setFieldValue('aliasFile',"")
    setFieldValue(imageUploadField ? imageUploadField : "","")
    setIsAliasValidFormat(false)
    type && type.split(" ")[0] === Type.Edit &&  changeSchema!== undefined ? changeSchema(true) : null
  }

  function uploadCsvFile(e: React.BaseSyntheticEvent){
   const myFile = e.target.files[0];
    if(myFile && myFile?.type === "text/csv"){
      e.target.value = '';
      setDisable(true)
      setSelectedFileName(myFile?.name)
      setCSVFile(myFile)
      setFieldValue('myFile', myFile);
      setCsvWrongFormat(false)
      setCsvSubmitted(false)
    }else{
      e.target.value = '';
      setFieldValue('myFile', myFile ? myFile : CSVFile)
      setFile(myFile ? myFile : CSVFile)
      setCsvWrongFormat(true)
      setCsvSubmitted(false)
    }
  
  }
const handleImageSubmit = () => {
  if (selectedFileName || !imageSubmitted) {
      if (imageWrongFormat) {
          setImageWrongFormat(false);
          setImageSubmitted(true);
      }else if(file && isValidFormat){
        setImageSubmitted(false);
      }else if(isImageRequired === 'not required'){
        setImageSubmitted(false);
      }else{
        setImageSubmitted(true);
      }
      
  }else{
    setImageSubmitted(true);
  }

};

function handleCommunityType(e:any){
  if(type && type.split(" ")[0] === Type.Edit){
    if(e?.target?.value === 'ANON'){
    setCommunityError(true)
    setIsDisable(false)
    setTimeout(() =>{
      setCommunityError(false)
    },1500)
    }else{
      setCommunityError(false)
      setIsDisable(false)
      setCommunityType(e?.target?.value)
    }
  }else{
    setCommunityError(false)
    setIsDisable(false)
    setCommunityType(e?.target?.value)
  }
}
function handleRemoveTag(option:any){

}
function handleSavePost(data , rowData){
setPost(data ? data : "")
setPostData(rowData ? rowData : "")
setFieldValue('promptDetailId', rowData[0]?.id)
}
const handleScroll = (event , setPage , rowCount? ,paginationSize?) => {
  const bottom =
    event.target.scrollHeight === event.target.clientHeight + event.target.scrollTop;
    if (bottom && paginationSize < rowCount) {
      setPage();
    }
};
return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit}>
      {fields?.map((field : any) => (
        <>
        <div  key={field.name}>
          {field.type === 'select' ? field.name !== 'communityType' && field.name !== 'communityTopic'? (
            <FormControl  fullWidth variant="filled" margin="normal">
            <Autocomplete
               id="checkboxes-tags-demo"
               options={field.items}
               onChange={(e:any, value:any) =>{setSelectedValues(value ? value[field?.key] : ""),  setFieldValue(field.name ,value ?  value.candidate_LINKED_IN_ID === "ANONYMOUS" ? 'ANON' : value.candidate_LINKED_IN_ID  : null )}}
               getOptionLabel={(option:any) => option[`${field.key}`]}
               renderOption={(props, option, { selected }) => (
                 <li {...props} key={field.uniqueKey}>
                   {option[`${field.key}`]}
                 </li>
               )}
               defaultValue={field?.default && field?.items[0]}
               style={{ width: 'auto' }}
               onClose={() =>{ if(field.close) {field.close()}}}
               renderInput={(params) => (
                 <TextField {...params}  id={field.name}
                 onBlur={handleBlur}
                 required={field.required}
                //  value={editedDetails ? editedDetails.communityType :""}
                 name={field.name} 
                 onChange={(e:any) => {
                  // setSelectedValues(e?.target?.value)
                  if(field.callBack)
                  {field.callBack(e.target.value)
                  }}} label={field.label}
                 />
     )}
   />
    {touched[field.name] && errors[field.name]  ? <span style={{color:'red' }}>{`${errors[field.name]}`}</span> : null}
           </FormControl>
          ): field.name === 'communityTopic' ? (
    
    <FormControl fullWidth variant="filled" margin="normal">
   <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={field.items}
      value={selectedOptions}
      onClose={() => field?.reset()}
      loading={field?.loading}
      loadingText={"Loading..."}
      ListboxProps={
        {
          onScroll:(e) => handleScroll(e , field?.callBack , field?.rowCount , field?.paginationSize)
        }
      }
      onChange={(e, value) => {
        const limitedValue = value.slice(0, 2);
        setSelectedOptions(limitedValue.length === 0 ? [field.items[0]] : limitedValue);
      }}
      disableCloseOnSelect
      getOptionLabel={(option) => option[field.key]} // Assert type of option
      renderOption={(props, option) => {
        const isSelected = selectedOptions.some((selectedOption) => selectedOption?.id === option?.id);
        return (
          <li {...props} style={{ color: option.hexColor }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={isSelected}
            />
            {option[field.key]}
          </li>
        );
      }}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <span
            {...getTagProps({ index })}
            key={option.id}
            style={{ backgroundColor: option.hexColor, color: '#FFF', padding: '2px 8px', borderRadius: 4 }}
          >
            {option[field.key]} <span onClick={() => handleRemoveTag(option)}>&times;</span>
          </span>
        ))
      }
      renderInput={(params) => (
        <TextField {...params} onChange={(e) => field?.handleFilterChange(e?.target?.value)} variant="outlined" label="Select options" placeholder="Select options" />
      )}
    />

  {/* {touched[field.name] && errors[field.name] && <span style={{ color: 'red' }}>{errors[field.name]}</span>} */}
</FormControl>

          ) : (
            <>
              <>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{field?.label}</InputLabel>
              <Select
                name={field?.name} 
                labelId="demo-simple-select-label"
                id={field?.name}
                error={communityError ? communityError : false}
                disabled={type && type?.split(" ")[0] === Type?.Edit ? communtiyType === 'ANON' ? true : false : isDisable}
                value={communtiyType}
                label={field?.label}
                onBlur={handleBlur}
                onChange={(e:any) => {
                  setFieldValue(field?.name,e?.target?.value)
                  handleCommunityType(e)
                }
              }
              >
              {field?.items?.map((item:any) => (
                <MenuItem key={item} value={item?.key}>{item?.key}</MenuItem>
              ))}
            
              </Select>
              {communityError && <FormHelperText><span style={{color:'red' }}>Can't select ANON type</span></FormHelperText>}
            </FormControl>
           
              </>
          
            </>
            
          ) : field.type === 'dropDown' ?  <FormControl fullWidth variant="filled" margin="normal"> 
           <Autocomplete
          multiple
          value={communityData ? communityData : []}
          limitTags={2}
          id="multiple-limit-tags"
          options={communityData ? communityData : []}
          getOptionLabel={(option) => option.communityName}
          onChange={(event, newValue) => {
            dispatch(setCommunitie(newValue)) 
          }}
          renderInput={(params) => (
            <TextField onClick={handleCommunities}  {...params} label="select communities"  />
          )}
        /></FormControl>: field.type === 'posts' ?  <FormControl fullWidth variant="filled" margin="normal"> 
        <Autocomplete
       value={postData?.find(option => option?.title === post) || null}
       id="multiple-limit-tags"
       options={postData ? postData : []}
       getOptionLabel={(option) => option?.title}
       onChange={(event, newValue) => {
       }}
       renderInput={(params) => (
         <TextField  onClick={handlePost}  {...params} label="select post"  />
       )}
     /></FormControl>: field.type === 'drop' ?   <>
        <Autocomplete
        style={{ marginTop: '23px' , marginBottom:'23px'  }}
        id={field.name}
        disabled={field?.disable}
        loading={field?.loading}
        loadingText={"loading..."}
        onClose={() => field?.reset && field?.reset()}
        ListboxProps={
          {
            onScroll:(e) => handleScroll(e , field?.callBack , field?.rowCount , field?.paginationSize)
          }
        }
        getOptionLabel={(option:any) => option[`${field.key}`]} 
        onBlur={handleBlur}
        options={field?.more ?[...field?.items, ...(field?.items.length > 0 ? [{ [field.key]: 'More' }] : [])] : field?.items}
        onChange={(e:any, value:any) =>{field && field?.details && field?.details(value ? value : ""),setSeeder(field?.name === 'candidateLinkedInId' ? value : null),setFieldValue(field.name ,value?.id),field?.handleChange &&field?.handleChange(value ? value : "")}}
        renderOption={(props , option) => (
          <>
            {option[field.key] === 'More' ? (
              <Button
              fullWidth
              disabled={field?.items?.length >= field?.totalCount}
              className="autocomplete-more-button"
              color="primary" variant="contained"
              onClick={() => handleMore(field)}
              >
                {field?.loading ? 'Loading..' : option[field.key]}
              </Button>
            ) : (
              <li {...props} key={field.uniqueKey}>
              <MenuItem disableRipple >
                {option[field.key]}
              </MenuItem>
              </li>
            )}
          </>
        )}
        renderInput={(params) => <TextField 
          onChange={(e) => field?.handleFilterChange && field?.handleFilterChange(e?.target?.value)}
           {...params}  label={field?.label} />}
      
      />
 { values[field?.name] && field?.picture? <>
  <Container><ImageContainer>
        <div>
   <Card> <CardMedia  component="img" alt="not available" height="45" image={seeder?.photoUrl} // Replace with the URL of your image
/>
</Card>  
        </div>
  </ImageContainer></Container> 
  <Typography  sx={{marginTop:'5px'}}><span style={{fontWeight:'bold'}}>About the seeder:</span> {seeder?.aboutMe}</Typography>
 </> : null}
 {touched[field.name] && errors[field.name]  ? <span style={{color:'red' }}>{`${errors[field.name]}`}</span> : null}
        </> : field.type === 'grouping' ? (
   
   <FormControl fullWidth>
   <InputLabel htmlFor="grouped-native-select">{`${field?.label}`}</InputLabel>
   <Select onChange={(e:any) => {
    const selectedOption = e.target.options[e.target.selectedIndex]
    const itemData = JSON.parse(selectedOption.getAttribute('data-item'))
   field?.category( itemData ? itemData : "",e?.target?.options[e.target.selectedIndex].getAttribute('data-category')),setFieldValue(field?.name , e?.target?.value)}} native defaultValue="" id="grouped-native-select" label={`${field?.label}`}>
     <option aria-label="None" value="" />
     <optgroup label={`${field.categoryFirst}`}>
     {field?.items?.map((item:any) =>{
      return <>
       <option data-item={JSON.stringify(item)} value={`${item?.id}`} data-category={false}>{`${item[field?.keyFirst]}`}</option>
      </> 
     })}
     </optgroup>
     <optgroup label={`${field.categorySecond}`}>
     {field?.items?.map((item:any) =>{
      return <>
       <option data-item={JSON.stringify(item)} value={`${item?.id}`} data-category={true}>{`${item[field?.keySecond]}`}</option>
      </> 
     })}
     </optgroup> 
   </Select>
 </FormControl>
          ): field.type === 'alias' ? (<>
            <div><FormControl fullWidth variant="outlined" margin="normal">
              <div style={{cursor:"pointer"}}>
              <Button disabled={disable}  style={{width:"100%" ,marginBottom:"13px"}} component="label" variant="contained" startIcon={<CameraAltIcon />}>
              <label style={{cursor:"pointer"}} htmlFor={field.name}>{field?.label || 'upload alias'}
              <Input
                type="file"
                id={field.name}
                name={field.name}
                onChange={(e) =>{
                  handleAliasChange(e)
                }}
                onBlur={handleBlur}
                value={values?.file}
                // error={touched[field.name] && Boolean(errors[field.name])}
                style={{ width: "0px"}}
              />
              </label>
              </Button>
            </div>
             {/* {touched[field.name] && errors[field.name] && aliasImageWrongFormat  ? <span style={{color:'red'}}>{`${errors[field.name]}`}</span> : aliasImageSubmitted ?  <span style={{color:'red'}}>{`Image is required`}</span> : null} */}
             <Container>
                  <ImageContainer>
                    <div>
                      {type && type.split(" ")[0] === Type.Add && aliasFile && isAliasValidFormat && <Card> <CardMedia component="img" alt="not available" height="45" image={aliasFile && URL.createObjectURL(aliasFile)} // Replace with the URL of your image
            />
            </Card>  }
              {type && type.split(" ")[0] === Type.Edit && aliasFile && isAliasValidFormat &&       <Card>
              <CardMedia
                component="img"
                alt="not available"
                height="45"
                image={typeof(aliasFile) === typeof("") ? aliasFile : URL.createObjectURL(aliasFile)}
              />
            </Card>  }
                
                    </div>
                    <div style={{marginLeft:"8px",fontSize:"0.8rem" , fontFamily:"Sans-serif",color:"grey" }} className="info">
                      {/* <div>{type && type.split(" ")[0] === Type.Add  && aliasFile && isAliasValidFormat && aliasFile.name}</div>
                      <div>{type && type.split(" ")[0] === Type.Edit && file && isValidFormat && file.name || file && isValidFormat && editedDetails &&  editedDetails.name}</div> */}
                    </div>
                  </ImageContainer>
            
                  {aliasFile && (
                    <ClearIconContainer onClick={removeAliasFile} className="clear-icon-container">
                      <Tooltip title="clear"><ClearIcon /></Tooltip>
                    </ClearIconContainer>
                  )}
            </Container>
            </FormControl>
            </div>
                       </>) :  field.type === 'file' ? (label === "" ? (<>
<div><FormControl fullWidth variant="outlined" margin="normal">
  <div style={{cursor:"pointer"}}>
  <Button disabled={disable}  style={{width:"100%" ,marginBottom:"13px"}} component="label" variant="contained" startIcon={<CameraAltIcon />}>
  <label style={{cursor:"pointer"}} htmlFor={field.name}>{field?.label || 'upload image'}
  <Input
    type="file"
    id={field.name}
    name={field.name}
    onChange={(e) =>{
      handleFileChange(e)
    }}
    onBlur={handleBlur}
    value={values?.file}
    // error={touched[field.name] && Boolean(errors[field.name])}
    style={{ width: "0px"}}
  />
  </label>
  </Button>
</div>
 {touched[field.name] && errors[field.name] && imageWrongFormat  ? <span style={{color:'red'}}>{`${errors[field.name]}`}</span> : imageSubmitted ?  <span style={{color:'red'}}>{`Image is required`}</span> : null}
 <Container>
      <ImageContainer>
        <div>
          {type && type.split(" ")[0] === Type.Add && file && isValidFormat && <Card> <CardMedia component="img" alt="not available" height="45" image={file && URL.createObjectURL(file)} // Replace with the URL of your image
/>
</Card>  }
  {type && type.split(" ")[0] === Type.Edit && file && isValidFormat &&       <Card>
  <CardMedia
    component="img"
    alt="not available"
    height="45"
    image={typeof(file) === typeof("") ? file : URL.createObjectURL(file)}
  />
</Card>  }
    
        </div>
        <div style={{marginLeft:"8px",fontSize:"0.8rem" , fontFamily:"Sans-serif",color:"grey" }} className="info">
          {/* <div>{type && type.split(" ")[0] === Type.Add  && file && isValidFormat && file.name}</div>
          <div>{type && type.split(" ")[0] === Type.Edit && file && isValidFormat && file.name || file && isValidFormat && editedDetails &&  editedDetails.name}</div> */}
        </div>
      </ImageContainer>

      {file && (
        <ClearIconContainer onClick={removeFile} className="clear-icon-container">
          <Tooltip title="clear"><ClearIcon /></Tooltip>
        </ClearIconContainer>
      )}
</Container>
</FormControl>
</div>
           </>) : 
  <>
 <div>

<Button disabled={disable} style={{width:"98%",marginBottom:"13px"}} component="label" variant="contained" startIcon={<CloudUploadIcon/>}>
  Upload CSV File
  <Input
    type="file"
    id={field.name}
    name={field.name}
    onChange={uploadCsvFile}
    onBlur={handleBlur}
    value={values.file}
    style={{ width: "0px"}}
  />
</Button>
<div>
{touched[field.name] && errors[field.name]  && CsvWrongFormat  ? <span style={{color:'red'}}>{ `${errors[field.name]}`}</span> : CsvSubmitted  ? <span style={{color:'red'}}>{ `File is required`}</span> :  null }
<Container>
      <ImageContainer>
        <div>
             {CSVFile ?  <img src={""} style={{ fontSize: '24px' , color:"grey" }} /> : ""}
          
        </div>
        <div style={{marginLeft:"8px",fontSize:"1rem" , fontFamily:"Sans-serif" , color:"grey" }} className="info">
        {selectedFileName ? selectedFileName : ""}
        </div>
      </ImageContainer>

      {CSVFile && (<ClearIconContainer onClick={removeFile} className="clear-icon-container">
      <Tooltip title="clear"><ClearIcon /></Tooltip>
     </ClearIconContainer>
      )}
</Container>
</div>

 </div>
  <div style={{marginTop:"15px" , height:"44px"}}>
  <a  href="https://docs.google.com/spreadsheets/d/1jhxe27C7yYB56bcUsWi7X1BIJIq3KWZkRnO_S6JIQEI/export?format=csv" download="sample.csv" target="_top" rel="noopener noreferrer"><Button onClick={closeModal}  startIcon={<DownloadingIcon />} style={{width:"98%" , height:"100%"}} component="label" variant="contained">Download Sample CSV</Button></a>
    </div>
  </>          



          ) : field.type === 'DatePicker' ? (<>
          <div style={{marginTop:"23px" }} > 
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <MobileDateTimePicker
            defaultValue={field?.defaultValue || null}  
            onChange={(value) => {setFieldValue(field.name, value, true)}}
            disablePast={field?.disableTimePicker  ?false :true}
            disableFuture={field?.disableTimePicker  ?true :false}
          format={field?.datePickerFormat || (field?.disableTimePicker ? "YYYY-MM-DD":"YYYY-MM-DD HH:mm:ss")}
         
            ampm={field?.disableTimePicker  ? false :true}
            views={field?.datePickerView  || ( field?.disableTimePicker  ? ['day','month','year']:['day','hours','minutes','month','seconds','year'])}
            onError={(newError) => setError(newError)}
            minDate={field?.minDate }
            maxDate={field?.maxDate}
            slotProps={{
              textField: {
                fullWidth: true,
                placeholder:'',
                required: field.required,
                id:field.name,
                onBlur:handleBlur,
                name:field.name,
                error:touched[field.name] && errors[field.name] ? true : false,
                label:field.label
              },
            }}
            
             />
          </LocalizationProvider>
          
          </div>
          {touched[field.name] && errors[field.name]  ? <span style={{color:'red' }}>{`${errors[field.name]}`}</span> : null}
          </>) : field.type === 'textarea' ? ( 
            <>
            <Textarea 
            style={{ marginTop: '23px'  }} 
            id={field.name}
            name={field.name}
            value={values[field.name]}  
            aria-label="minimum height" 
            minRows={3} 
            required={field.required}
            onBlur={handleBlur}
            onChange={handleChange}  
            placeholder={field.label} />
            {touched[field.name] && errors[field.name]  ? <span style={{color:'red' }}>{`${errors[field.name]}`}</span> : null}
            </> 
          ) :  field.type === 'checkbox'? <FormControlLabel
          control={<Checkbox
            id={field.name}
            checked={values[field.name] || false}
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
          />}
          label={field.label}
        />: (
            <>
            <TextField
            autoComplete='off'
              fullWidth
              required={field.required}
              id={field.name}
              name={field.name}
              label={field.label}
              variant="outlined"
              margin="normal"
              inputProps={{
                step:`${field?.step}`
              }}
              type={field.type || 'text'}
              value={values[field.name]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched[field.name] && Boolean(errors[field.name])}
              style={{ marginTop: '23px',...field.extraStyle }}
            />
            {touched[field.name] && errors[field.name]  ? <span style={{color:'red' }}>{`${errors[field.name]}`}</span> : null}
            </>
            
          )}
        </div>
        </>
      ))}

      <div style={{ textAlign:'center' }}>
        {type === "Add Postv2" ? <>
        <Box
        sx={{
          display:'flex',
          justifyContent:'space-evenly'
        }}
        >
        <Button   style={{ marginTop: '20px' }} onClick={() => { setCsvWrongFormat(false) , setCsvSubmitted(CSVFile ? false : true)  }  }  color="primary" variant="contained" type="submit">
          {title}
        </Button>
        <Button   style={{ marginTop: '20px' }} onClick={() => handleComments &&  handleComments({...values , ...seeder})}   color="primary" variant="contained" type="button">
          Save Post
        </Button>
        </Box>
        </>: <Button   style={{ marginTop: '20px' }} onClick={() => { setCsvWrongFormat(false) , setCsvSubmitted(CSVFile ? false : true) , handleImageSubmit() }  }  color="primary" variant="contained" type="submit">
          {title}
        </Button>}
        
      </div>
      <Dialog setCommunityFieldCallBack={setCommunityFieldCallBack} dialogOpen={dialogOpen} handleCommunityClose={handleCommunityClose}/>
      <PostDialog handleSavePost={handleSavePost}  openDialog={postOpen} handlePostClose={handlePostClose}/>
    </form>
  );
};

export default GenericForm;
