import { PaginationInterface } from "../../../types";
import {
  SubredditPayload,
  UpvoteBoosterResponse,
} from "../../components/form/type";
import axios from "../../interceptor/axiosInterceptor";

interface SubredditResponse {
  size: number;
  list: Array<string>;
  totalCount: number;
}

interface SubredditPostResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  communityId: string;
  subRedditName: string;
  createdBy: string;
  updatedBy: string | null;
}

export const getSubredditManager = async (pagination:PaginationInterface): Promise<
  UpvoteBoosterResponse<SubredditResponse>
> => {
  try {
    const response = await axios.get(
      `/linkedin-candidate/subreddit-manager/getAll?pageNumber=${pagination?.pageNo}&pageSize=${pagination?.pageSize}`
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const addSubredditManager = async (
  payload: SubredditPayload
): Promise<UpvoteBoosterResponse<SubredditPostResponse>> => {
  try {
    const response = await axios.post(
      `linkedin-candidate/subreddit-manager/addCommunitySubreddit`,
      payload
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteSubRedditManager = async (
  id: string
): Promise<UpvoteBoosterResponse<boolean>> => {
  try {
    const response = await axios.delete(
      `/linkedin-candidate/subreddit-manager/delete/${id}`
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
