import axios from "../../interceptor/axiosInterceptor";

export interface BotUser {
  id: string;
  email: string;
  username: string;
  profilePhotoUrl: string;
  isBot: boolean;
  prompt: string;
  description: string;
  faissFilePath: string;
}

export interface responseOfBotManager<T> {
  code: number;
  message: string;
  status: string;
  data: T;
}

export interface BotManagerFormValues {
  name: string;
  description: string;
  prompt: string;
  faissFile: File | null | string;
  profilePicture: File | null | string;
}

export const getBotManager = async (): Promise<responseOfBotManager<Array<BotUser>>> => {
  try {
    const response = await axios.get(`admin/bot/getAllBots`);
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const addBotManager = async (
  payload: BotManagerFormValues
): Promise<responseOfBotManager<BotUser>>=> {
  try {
    const response = await axios.post(`admin/bot/createBot`, payload);
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const editBotManager = async (
  payload: BotManagerFormValues,
  id: string
): Promise<responseOfBotManager<BotUser>>=> {
  try {
    const response = await axios.put(`admin/bot/updateBot/${id}`, payload);
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteBotManager = async (
  id: string
): Promise<responseOfBotManager<null>> => {
  try {
    const response = await axios.delete(`admin/bot/deleteBot/${id}`, {});
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};
