import * as Yup from 'yup';

const postV2Schema = Yup.object().shape({
communityId: Yup.string().required('Community is required'),
candidateLinkedInId: Yup.string().required('Select sedder is required'),
title: Yup.string().required('Title is required'),
dateTime: Yup.date().required('Date is required'),
});

export default postV2Schema;
