import React from 'react'
import { Layout } from '../../layout/Layout'
import { Button } from '@mui/material'
import {  GridColDef, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import SeedersGeneralisedComponent from './SeedersGeneric';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetSeeders, selectSeederState, setCommunitie, setType } from '../../app/seeders/seeders';
import SwipeMode from '../SwipeMode/swipeMode';

export default function Seeders() {
  const [addModal , setAddModal] = React.useState<boolean>(false)
  const [editModal, setEditModal] = React.useState(false);
  const [industryId, setIndustryId] = React.useState<string>("");
  const [editIndustryDetails , setEditIndustryDetails] = React.useState<any>(null)
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [swipeMode , setSwipeMode] = React.useState(false)
  const [swipeEdit , setSwipeEdit] = React.useState(false)
  const dispatch = useAppDispatch();

  const closeModal = () =>{
    setAddModal(false)
    setEditModal(false) 
    setDeleteModal(false)
    setSwipeEdit(false)
    dispatch(resetSeeders())
  }

const editIndustryModal = (params :any) =>{
  const idArray = params?.row?.communities?.map((community:any) => community.id);
  const payload = {
    aboutMe: params && params?.row?.aboutMe,
  anonymousName: params?.row?.anonymousName,
  username: params?.row?.username,
  communities: idArray,
  email:  params?.row?.email,
  firstName:  params?.row?.firstName,
  id:  params?.row?.id,
  isSeeder: true,
  lastName:  params?.row?.lastName,
  photoUrl:  params?.row?.photoUrl,
  aliasUrl: params?.row?.aliasUrl
}
setEditModal(true)
setEditIndustryDetails(payload ? {...payload,birthday:params?.row.birthday} : [])
  }


function deleteIndustryModal(id: string) {
  setIndustryId(id)
  setDeleteModal(true)
}
function handleSwipeMode(){
  setSwipeMode(true)
}
const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'lastName', headerName: 'Last Name', flex: 1,renderCell: (params) => (params.value ? params.value : 'No Data')  },
  { field: 'photoUrl',type:"actions", headerName: 'Logo',  width:10, flex: 1,renderCell: (params) => <Stack direction="row" spacing={2}>
  <Avatar alt="Remy Sharp" src={params ? params.value : ""} />
</Stack> },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'actions',
    flex: 1,
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          showInMenu
          onClick={() => {deleteIndustryModal(params?.row?.id)}}
          />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          showInMenu
          onClick={() => {editIndustryModal(params) ,dispatch(setType('Edit')), dispatch(setCommunitie(params?.row?.communities))} }
        />,
      ];
    },
  }

]
  return (
    <>
    <Layout  title="Seeders">
 
    <div>
    <div style={{ display:`${swipeMode ? 'block' : 'none'}`, textAlign: "right", marginBottom: "10px", outline: 'none' }}><Button sx={{marginRight:"10px"}} onClick={() => {setEditModal(true), setSwipeEdit(true), dispatch(setType('Edit'))}}  variant="contained">Edit</Button>  <Button onClick={() => setSwipeMode(false)}  variant="contained">Exit Swipe Mode</Button></div>
    <div style={{ display:`${swipeMode ? 'none' : 'block'}`, textAlign: "right", marginBottom: "10px", outline: 'none' }}>  <Button onClick={() => handleSwipeMode()}  variant="contained">Swipe Mode</Button>   <Button onClick={() => setAddModal(true)}  variant="contained">Add Seeder</Button></div>
   {swipeMode ? <SwipeMode swipeEdit={swipeEdit} industryId={industryId} deleteIndustryModal={deleteModal} editDetails={editIndustryDetails} editIndustryModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal}/> : <SeedersGeneralisedComponent industryId={industryId} deleteIndustryModal={deleteModal} editDetails={editIndustryDetails} editIndustryModal={editModal} columns={columns} closeModal={closeModal} addModal={addModal} /> } 
    </div>
    </Layout>
    </>
   
  )
}
